.btn-danger, button.btn-danger:not(:disabled) {
    background-color: $m-red;
    color: $s-white-fff;
    border-color: $m-red;
}

.btn-white, button.btn-white:not(:disabled) {
    color: $m-dark-gray;
    background-color: $white;
    box-shadow: 0 2px 4px rgba(0, 0, 0, .08);
    border: 1px solid lighten($s-blue, 35%);

    &:hover {
        box-shadow: 0 5px 14px rgba(0, 0, 0, .18);
    }

    &:focus {
        border-color: $s-blue;
    }

    &.w-outline {
        border-color: rgba(99, 111, 130, 0.25);
    }
}

.btn-white-wide, button.btn-white-wide:not(:disabled) {
    color: $m-dark-gray;
    background-color: $white;
    box-shadow: 0 2px 4px rgba(0, 0, 0, .08);
    border: 1px solid lighten($s-blue, 35%);
    width:65px;
    padding-top: unset;
    padding-bottom: unset;


    &:hover {
        box-shadow: 0 5px 14px rgba(0, 0, 0, .18);
    }

    &:focus {
        border-color: $s-blue;
    }

    &.w-outline {
        border-color: rgba(99, 111, 130, 0.25);
    }
}

.btn-gray, button.btn-gray:not(:disabled) {
    color: $white;
    background-color: $m-dark-gray;
    box-shadow: 0 2px 4px rgba(0, 0, 0, .08);
    border: 1px solid lighten($s-blue, 35%);

    &:hover {
        box-shadow: 0 5px 14px rgba(0, 0, 0, .18);
        color: $s-black;
        background-color: $m-light-gray;
    }

    &:focus {
        border-color: $s-blue;
    }

    &.w-outline {
        border-color: rgba(99, 111, 130, 0.25);
    }
}

.btn-light-gray, button.btn-light-gray:not(:disabled) {
    color: $s-black;
    background-color: $m-light-gray;
    box-shadow: 0 2px 4px rgba(0, 0, 0, .08);
    border: 1px solid lighten($s-blue, 35%);

    &:hover {
        box-shadow: 0 5px 14px rgba(0, 0, 0, .18);
        color: $white;
        background-color: $m-gray;
    }

    &:focus {
        border-color: $s-blue;
    }

    &.w-outline {
        border-color: rgba(99, 111, 130, 0.25);
    }
}

.sw-teal-btn, button.sw-teal-btn:not(:disabled) {
    background-color: $s-teal;
    border-color: $s-teal;
    color: $white;

    &:hover {
        background-color: darken($s-teal, 5%);
        border-color: darken($s-teal, 10%);
        color: $white;
    }
}

.sw-red-btn, button.sw-red-btn:not(:disabled) {
    background-color: $m-red;
    color: $s-white-fff;
    border-color: $m-red;

    &:hover {
        background-color: darken($m-red, 2.5%);
        border-color: darken($m-red, 5%);
        color: $white;
        box-shadow: 0 4px 11px rgba(0, 0, 0, 0.42), 0 16px 9px rgba(0, 0, 0, 0.18);
    }
}

.sw-teal-btn, button.sw-teal-btn:not(:disabled) {
    background-color: $m-teal;
    color: $white;
    border-color: $m-teal;

    &:hover {
        background-color: darken($m-teal, 2.5%);
        border-color: darken($m-teal, 5%);
        color: $s-black;
        box-shadow: 0 4px 11px rgba(0, 0, 0, 0.42), 0 16px 9px rgba(0, 0, 0, 0.18);
    }
}

.sw-green-btn, button.sw-green-btn:not(:disabled) {
    background-color: $m-green;
    color: $white;
    border-color: $m-green;

    &:hover {
        background-color: darken($m-green, 2.5%);
        border-color: darken($m-green, 5%);
        color: $s-black;
        box-shadow: 0 4px 11px rgba(0, 0, 0, 0.42), 0 16px 9px rgba(0, 0, 0, 0.18);
    }
}


.sw-blue-btn, button.sw-blue-btn:not(:disabled) {
    background-color: $s-blue;
    color: $s-white-fff;
    border-color: $s-blue;

    &:hover {
        background-color: darken($s-blue, 2.5%);
        border-color: darken($s-blue, 5%);
        color: $white;
        box-shadow: 0 4px 11px rgba(0, 0, 0, 0.42), 0 16px 9px rgba(0, 0, 0, 0.18);
    }
}

    .sw-transparent-btn, button.sw-transparent-btn:not(:disabled) {
        background-color: transparent;
        color: $s-white-fff;
        border-color: transparent;

        &:hover {
            background-color: transparent;
            border-color: transparent;
            color: $s-white-fff;
            box-shadow: 0 4px 11px rgba(0, 0, 0, 0.42), 0 16px 9px rgba(0, 0, 0, 0.18);
        }
    }

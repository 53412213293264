.text-teal {
    color: $s-teal !important;
}

a.text-teal,
a .text-teal {
    color: $s-teal !important;

    &:hover {
        color: darken($s-teal, 20%);
    }
}

.empty-state {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 2rem;

    .empty-state-icon-container {
        align-items: center;
        background: lighten($s-teal, 50%);
        border-radius: 50%;
        display: flex;
        height: 70px;
        margin-bottom: 2rem;
        justify-content: center;
        width: 70px;
    }

    .empty-state-icon {
        color: $s-teal;
        font-size: 40px;
    }

    .title {
        font-size: 24px;
    }

    .subtitle {
        font-size: 18px;
        opacity: 0.8;
    }
}

.back-ground-card {
    background-color: whitesmoke;
}

.photo-responsive {
    object-fit: contain;
    max-height: 600px;
}

.input-small {
    width: 100px;
}

.price-input {
    width: 130px;
}

.white-space-nowrap {
    white-space: nowrap;
}

.smaller-top-padding {
    padding-top: .5em;
}

.btl-round {
    border-top-left-radius: 4px;
}

.bbl-round {
    border-bottom-left-radius: 4px;
}

// @media (max-width: 524px) {
//     .worksheet-static {
//         position: relative;
//     }
// }

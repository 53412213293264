.form-control:focus {
    border: 2px solid #00838f;
    box-shadow: none;
}

.multi-select-container {
  margin-top: .5vh !important;
  width: inherit !important;
  border: none !important;
}

.c-btn {
  padding: unset !important;
  span {
      margin-left: .5rem !important;
  }
  .c-token {
      background-color: white !important;
  }
  .c-label {
      color: black !important;
  }
  .single-select-mode.pure-checkbox input[type=checkbox]:checked + label:before {
    background: red !important;
  }
  .pure-checkbox input[type=checkbox]+label:before {
      background-color: white !important;
      border-color: black !important;
      border: 1px solid black !important;
  }
  input[type=checkbox]:checked + label:before {
      background-color: red !important;
      border-color: red !important;
  }
  .pure-checkbox input[type=checkbox]+label:after {
     border-color: white !important;
     background-color: red !important;
  }
}

.wholesale-volume-grid {
    flex-grow: 1;
    display: grid;
    grid-template-columns: 50%;
    grid-template-rows: 100%;
    padding: 0.5rem 0 0 0.5rem;
}

.wholesale-grid-item {
    padding: 0 0.5rem 0.5rem 0;

    &.col-1--row-1 {
        grid-column: 1;
        grid-row: 1;
    }

    &.col-2--row-1 {
        grid-column: 2;
        grid-row: 1;
    }
}

// Empty States

// Empty states on the Customer Profile Overview page
.customer-overview-tiles-empty-state {
    //background-color: rgba(0, 0, 0, .07);
    text-align: center;
    padding-bottom: 2rem;
    border-radius: 0.25rem;

    .icon {
        width: 4rem;
        height: 4rem;
        background-color: rgba(0, 0, 0, .05);
        border: 1px solid rgba(0, 0, 0, .08);
        border-radius: 10000rem;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 2rem;
        display: flex;
        align-content: center;
        align-items: center;
        justify-content: center;
        text-align: center;
        font-size: 2rem;
    }
}

.retail-profile-project-grid {
    flex-grow: 1;
    display: grid;
    grid-template-columns: 40% 60%;
    grid-template-rows: 50% 50%;
    padding: 0.5rem 0 0 0.5rem;
}

.retail-profile-project-grid-item {
    padding: 0 0.5rem 0.5rem 0;

    &.col-1--row-1 {
        grid-column: 1;
        grid-row: 1;
        padding-bottom: 0.5rem;
    }

    &.col-1--row-2 {
        grid-column: 1;
        grid-row: 2;
    }

    &.col-2--row-1-2 {
        grid-column: 2;
        grid-row-start: 1;
        grid-row-end: 3;
    }
}

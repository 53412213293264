.splash-container {
    align-items: center;
    background-image: url('https://images.unsplash.com/photo-1503971052383-6d71287aef49?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1374&q=80');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
    height: 100vh;
    justify-content: center;
    position: relative;
    overflow: hidden;
    width: 100vw;
}

.splash-dialog {
    border-radius: 10px;
    box-shadow: 0 0 1rem 0 rgba(0, 0, 0, .2);
    display: flex;
    flex-direction: column;
    height: 50%;
    justify-content: center;
    position: absolute;
    padding: 2rem 4rem;
    width: 50%;

    @media (max-width: 600px) {
        width: 90%;
        padding: 1rem 1rem;
    }

    &:before {
        border-radius: 10px;
        content: "";
        position: absolute;
        background: inherit;
        z-index: 0;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        box-shadow: inset 0 0 2000px rgba(255, 255, 255, .25);
        backdrop-filter: blur(5px);
    }
}

.splash-content {
    z-index: 10;

    .title {
        color: white;
        font-size: 42px;
        font-weight: bold;
        margin-bottom: 0;
        z-index: 1;

        @media (max-width: 600px) {
            font-size: 32px;
        }
    }

    .subtitle {
        color: rgba(255, 255, 255, 0.75);
        font-size: 32px;
        margin-bottom: 2rem;

        @media (max-width: 600px) {
            font-size: 24px;
        }
    }
}

.material-checkbox {
    cursor: pointer;
    position: relative;
    width: 16px;
    height: 16px;
    margin-right: .25rem;
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    outline: none;
    transition: 0.2s ease-in;
    border: none;

    &:focus {
        outline: -webkit-focus-ring-color auto 5px;
    }
}

.material-checkbox:before {
    position: absolute;
    top: 4px;
    left: 0;
    content: '';
    width: 1rem;
    height: 1rem;
    display: inline-block;
    background-color: $s-lite-white-f7f;
    border: solid 1px $s-grey-707;
    border-radius: 2px;
    transition: 0.25s ease-in;
}

.material-checkbox:after {
    font-family: 'Material Icons';
    content: 'check';
    font-weight: bold;
    font-size: 13px;
    position: absolute;
    top: 2px;
    left: 1px;
    color: $white;
    transform: scale(0);
    transition: 0.25s ease-in;
}

.material-checkbox:checked:after
{
    transform: scale(1);
}

.material-checkbox:checked:before
{
    border-color: $m-red;
    background-color: $m-red;
}

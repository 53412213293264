// toggle
.material-switch {
    position: relative;
}

.material-switch > input[type="checkbox"] {
    //display: none;
    width: 0.1px;
    height: 0.1px;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
}

.material-switch label {
    display: block;
}

.material-switch input:focus + label::before {
    outline: auto 5px highlight;
    outline: -webkit-focus-ring-color auto 5px;
    border-color: -webkit-focus-ring-color;
    opacity: 1;
}

.material-switch {
    //margin-top: .6rem;
}

.material-switch > label {
    cursor: pointer;
    height: 14px;
    position: relative;
    width: 35px;
    margin-bottom: 0;
}

.material-switch > label::before {
    background: $s-grey-8a8;
    box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.5);
    border-radius: 8px;
    content: '';
    height: 16px;
    //margin-top: -8px;
    position: absolute;
    opacity: 0.3;
    transition: all 0.4s ease-in-out;
    width: 40px;
}

.material-switch > label::after {
    background-color: $s-white-fff;
    border-radius: 16px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
    content: '';
    height: 24px;
    left: -4px;
    //margin-top: -8px;
    position: absolute;
    top: -4px;
    transition: all 0.3s ease-in-out;
    width: 24px;
}

.material-switch > input[type="checkbox"]:checked + label::before {
    background: inherit;
    opacity: 1;
}

.material-switch > input[type="checkbox"]:checked + label::before {
    background-color: #edbaab;
    box-shadow: none;
    opacity: 1;
}

.material-switch > input[type="checkbox"]:checked + label::after {
    background: inherit;
    left: 20px;
    background-color: $m-red;
    opacity: 1;
}

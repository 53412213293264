.project-summary-grid {
    flex-grow: 1;
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
    grid-template-rows: 50% 50%;
    padding: 0.5rem 0 0 0.5rem;
}

.project-summary-grid-item {
    padding: 0 0.5rem 0.5rem 0;

    &.col-1--row-1 {
        grid-column: 1;
        grid-row: 1;
    }

    &.col-2-3--row-1 {
        grid-column-start: 2;
        grid-column-end: 4;
        grid-row: 1;
    }

    &.col-4--row-1 {
        grid-column: 4;
        grid-row: 1;
    }

    &.col-1--row-2 {
        grid-column: 1;
        grid-row: 2;
    }

    &.col-2-3--row-2 {
        grid-column-start: 2;
        grid-column-end: 4;
        grid-row: 2;
    }

    &.col-4--row-2 {
        grid-column: 4;
        grid-row: 2;
    }
}

@use '@angular/material' as mat;
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-typography-config());`
@include mat.all-component-typographies();
@include mat.core();

$shield-app-primary: mat.define-palette(mat.$red-palette, 900);
$shield-app-accent:  mat.define-palette(mat.$cyan-palette, 800);
$shield-app-warn:    mat.define-palette(mat.$red-palette, A700);
$shield-app-secondary: mat.define-palette(mat.$grey-palette, 400);
$shield-app-dark-gray: mat.define-palette(mat.$grey-palette, 600);
$shield-app-success: mat.define-palette(mat.$green-palette, 500);

$shield-app-theme: mat.define-light-theme((
    color: (
        primary: $shield-app-primary,
        accent: $shield-app-accent,
        warn: $shield-app-warn
    )
));

@include mat.all-component-themes($shield-app-theme);

$primary: mat.get-color-from-palette($shield-app-primary);
$secondary: mat.get-color-from-palette($shield-app-secondary);
$accent: mat.get-color-from-palette($shield-app-accent);
$warn: mat.get-color-from-palette($shield-app-warn);
$danger: $primary;
$success: mat.get-color-from-palette($shield-app-success);

$theme-colors: (
    "primary":      $primary,
    "secondary":    $secondary,
    "warning":      $warn,
    "info":         $accent,
    "danger":       $danger,
    "success":      $success,
    "m-gray": #757575
);

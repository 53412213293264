@use '@angular/material' as mat;
@import "theme";

// Bootstrap
@import "/node_modules/bootstrap/scss/bootstrap";

// This is here for specificity reasons
//@import "6. Components/_components.breakpoints.scss";

// 1. Settings
@import "1. Settings/_settings.fonts.scss";
@import "1. Settings/_settings.colors.scss";
@import "1. Settings/_settings.body.scss";
@import "1. Settings/_settings.links.scss";
@import "1. Settings/_settings.headings.scss";

// 2. Tools

// 3. Generic
@import "3. Generic/_generic.box-sizing.scss";

// 4. Elements
@import "4. Elements/_elements.navigation.scss";
@import "4. Elements/_elements.lists.scss";
@import "4. Elements/_elements.buttons.scss";
@import "4. Elements/_elements.forms.scss";
@import "4. Elements/_elements.material-checkbox.scss";
@import "4. Elements/_elements.material-switch.scss";
@import "4. Elements/_elements.alerts.scss";
@import "4. Elements/_elements.tables.scss";
@import "4. Elements/_elements.auth.scss";
@import "4. Elements/_elements.empty-states.scss";
@import "4. Elements/_elements.cards.scss";
@import "4. Elements/elements.badges-filter-chips";
@import "4. Elements/elements.material-datepicker";
@import "4. Elements/elements.chips";

// 5. Objects
@import "5. Objects/_objects.img.scss";
@import "5. Objects/_objects.font-icons.scss";
@import "5. Objects/_objects.grids.scss";

// 6. Components
@import "6. Components/_components.global-header.scss";
@import "6. Components/_components.main-container.scss";
@import "6. Components/_components.collapse.scss";
@import "6. Components/_components.footer.scss";
@import "6. Components/_components.angular-multi-select.scss";
@import "6. Components/components.splash";


// 7. Overrides
@import "7. Overrides/_overrides.input-fields.scss";
@import "7. Overrides/_overrides.contains.scss";
@import "7. Overrides/_overrides.utilityclasses.scss";
@import "7. Overrides/_overrides.mobile-utilities.scss";
@import "7. Overrides/_overrides.helpers.scss";
@import "7. Overrides/_overrides.angular-components.scss";

.calendar-header {
    background: mat.get-color-from-palette($shield-app-dark-gray);
    margin-top: 1rem;
    padding: 1rem;
}

.calendar-header-year {
    color: rgba(255,255,255,0.8);
    font-weight: bold;
}

.calendar-header-date {
    color: #ffffff;
    font-size: 1.5rem;
    margin-top: 2rem;
    text-align: right;
}

body {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

app-customer {
    display: inherit;
    flex-direction: inherit;
    flex-grow: inherit;
}

p {
    line-height: 100%;
}

.w-0 {
    width: 0;
}

@import "chain-profile-grid";
@import "mat-stepper";
@import "profile-grid";
@import "project-summary-grid";
@import "retail-profile-project-grid";
@import "retail-volume-grid";
@import "wholesale-grid";
@import "wholesale-volume-grid";
@import "loader";

@include media-breakpoint-down(sm) {
    .container {
        padding-left: 0;
        padding-right: 0;

        > .col-12 {
            padding: 0;
        }
    }
}

@include media-breakpoint-down(md) {
    .container {
        width: 100%;
        max-width: 100%;
    }
}

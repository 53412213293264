.loader-logo {
    margin-bottom: 1rem;
}

.loader-spin {
    animation: rotateAnimation 5s linear infinite;
}

@keyframes rotateAnimation {
    0%,10%,40% {
        transform: rotateY(0deg);
    }
    5%,20%,80%,100% {
        transform: rotateY(360deg);
    }
}

.sheen-container {
    overflow: hidden;
    position: relative;

    .sheen {
        background-color: rgba(255, 255, 255, 0.75);
        height: 100%;
        width: 20%;
        display: block;
        position: absolute;
        top: 0;
        left: -100%;
        transform: skewX(-45deg) translateX(0);
        transition: none;
        opacity: 0;
        animation: shiney 5s linear infinite;

        &:before {
            content: "";
            background-color: rgba(255, 255, 255, 0.25);
            height: 100%;
            width: 10%;
            display: block;
            position: absolute;
            top: 0;
            left: -100%;
        }
    }

    .sheen-fast {
        background-color: rgba(255, 255, 255, 0.75);
        height: 100%;
        width: 20%;
        display: block;
        position: absolute;
        top: 0;
        left: -100%;
        transform: skewX(-45deg) translateX(0);
        transition: none;
        opacity: 0;
        animation: shiney 3s linear infinite;

        &:before {
            content: "";
            background-color: rgba(255, 255, 255, 0.25);
            height: 100%;
            width: 10%;
            display: block;
            position: absolute;
            top: 0;
            left: -100%;
        }
    }
}

@keyframes shiney {
    0%,90% {
        left: -100%;
        opacity: 0;
    }
    100% {
        left: 200%;
        opacity: 1;
        transition: all 0.75s ease-in-out;
    }
}

.loader-fade {
    animation: fade 1s linear;
}

@keyframes fade {
  0% { opacity: 0 }
  100% { opacity: 1 }
}

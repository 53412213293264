.cdk-drag {
    max-height: 100vh;
    max-width: 100vw;
    overflow: auto;
}

.cdk-overlay-container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: auto;
    width: auto;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
}

.cdk-overlay-container, .cdk-overlay-pane {
    z-index: 9999 !important;
}

.basic-modal {
    max-height: 100vh;
    overflow: auto;
}

.mat-mdc-card,
.mat-expansion-panel {
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.25) !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
.mat-form-field-wrapper {
    padding-bottom: 0 !important;
}

.mat-form-field-invalid {
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
    .mat-form-field-outline {
    background-color: #f5dfdf;
    }
}

.mat-drawer {
    left: 0;
    position: fixed !important;
    z-index: 999999 !important;
}

// Swisher Colors
$s-white-fff: #ffffff;
.s-white-fff-color { color: $s-white-fff; }

$s-lite-white-f7f: #f7f7f7;
.s-lite-white-f7f-color { color: $s-lite-white-f7f; }

$s-lite-white-faf: #FAFAFA;
.s-lite-white-faf { color: $s-lite-white-faf; }

$s-grey-f0f: #f0f0f0;
.s-grey-f0f-color { color: $s-grey-f0f; }

$s-grey-d6d: #d6d6d6;
.s-grey-d6d-color { color: $s-grey-d6d; }

$s-grey-bdb: #bdbdbd;
.s-grey-bdb-color { color: $s-grey-bdb; }

$s-grey-a3a: #a3a3a3;
.s-grey-a3a-color { color: $s-grey-a3a; }

$s-grey-8a8: #8a8a8a;
.s-grey-8a8-color { color: $s-grey-8a8; }

$s-grey-707: #707070;
.s-grey-707-color { color: $s-grey-707; }

$s-grey-575: #575757;
.s-grey-575-color { color: $s-grey-575; }

$s-grey-3d3: #3d3d3d;
.s-grey-3d3-color { color: $s-grey-3d3; }

$s-black-000: #000000;
.s-black-000-color { color: $s-black-000; }


// Swisher Red
$s-extra-lite-red: #F7EBEE; // rba(247, 220, 225)
.s-extra-lite-red-color { color: $s-extra-lite-red; }

$s-lite-red: #BE6B7B;

$s-red: #920923; // rgb(146, 9, 35)
.s-red-color { color: $s-red; }



// New Swisher Brand Colors
$s-black: #22221f;
// rgb(34, 34, 31)
.swisher-black { color: $s-black }

$s-red: #bd353c;
// rgba(189, 53, 60)
.swisher-red { color: $s-red }

$s-yellow: #e0b138;
.swisher-yellow { color: $s-yellow }
// rgb(224, 177, 56)

$s-green: #787a5a;
// rgb(120, 122, 90)

$s-blue: #636f82;
.swisher-blue { color: $s-blue }
// rgb(99, 111, 130)

$s-tan: #baa584;
// rgb(186, 165, 132)

$s-grey: #ada89c;
// rgb(173, 168, 156);
.swisher-gray { color: $s-grey }

$s-teal: #2E8487;
// rgb(46, 132, 135)

//mockup colors
$m-red: #a21d2a;
.m-red { color: $m-red }
//rgb(162, 29, 42)

$m-teal: #348289;
.m-teal { color: $m-teal }
//rgb(52, 130, 137)

$m-darkest-gray: #454545;
.m-darkest-gray { color: $m-darkest-gray }

$m-dark-gray: #656565;
.m-dark-gray { color: $m-dark-gray }
//rgb(101, 101, 101)

$m-gray: #757575;
.m-gray { color: $m-gray }
//rgb(117, 117, 117)

$m-light-gray: #cfcfcf;
.m-light-grey { color: $m-light-gray }

$m-green: #4caf50;
.m-green { color: $m-green }
//rgb(76, 175, 80)

.table {
    font-size: 0.75rem;
    color: $s-black;
    margin-bottom: 0;

    a {
        //color: $primary;
        text-decoration: underline;

        &:hover {
            text-decoration: none;
        }
    }
}

.table-header-sm {
    th {
        font-size: 0.75rem !important;
    }
}

.table th, .table td {
    vertical-align: middle;
    border-color: lighten($s-blue, 35%);
}

.table thead th {
    border-color: lighten($s-blue, 35%);
    background-color: $s-white-fff;
    color: $s-black;
}

.table th {
    cursor: pointer;
}

.table-bordered th, .table-bordered tr {
    //border-right: none;
    //border-left: none;
    background-color: $s-white-fff;
}

.table-bordered th{
    background-color: $s-white-fff;
}

.table-bordered thead th, .table-bordered thead td {
    border-bottom-width: 1px;
}

.table th {
    font-weight: 500;
    color: #000000 !important;
    padding-left: 0.3;
    padding-right: 0.3;
    // font-size: 0.60rem;
}

.table-striped tbody tr:nth-of-type(odd) {
    background-color: lighten($s-blue, 50%);
}


.table-hover tbody tr:hover {
    background-color: lighten($s-blue, 35%);
}





.is-red {
    background-color: $s-extra-lite-red;
    color: $m-red;
    font-weight: bold;
}

.location-icon {
    opacity: 0;
    visibility: hidden;
    color: $s-teal;

    &.is-priority {
        visibility: visible;
        opacity: 1;
    }
}

.routed-icon {
    opacity: 0;
    visibility: hidden;
    color: $s-teal;

    &.is-routed {
        visibility: visible;
        opacity: 1;
    }
}

.table thead th.is-being-filtered {
    text-decoration: underline;
}

.filter-chip {
    background-color: $m-light-gray;
    border-radius: 25px;
    color: $m-darkest-gray;
    font-weight: normal;
}

.filter-chip:hover {
    background-color: #22221f;
    color: $s-white-fff;
}

.bg-white {
    background-color: $s-white-fff;
}

.flex-grow-2 {
    flex: 2!important;
}

.short-ellipsis {
    white-space: nowrap;
    width: 41ch;
    overflow-x: hidden;
    text-overflow: ellipsis;
    display: inline-block;
}

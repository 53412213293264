@font-face {
  font-family: "Flaticon";
  src: url("./Flaticon.eot");
  src: url("./Flaticon.eot?#iefix") format("embedded-opentype"),
       url("./Flaticon.woff2") format("woff2"),
       url("./Flaticon.woff") format("woff"),
       url("./Flaticon.ttf") format("truetype"),
       url("./Flaticon.svg#Flaticon") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "Flaticon";
    src: url("./Flaticon.svg#Flaticon") format("svg");
  }
}

[class^="flaticon-"]:before, [class*=" flaticon-"]:before,
[class^="flaticon-"]:after, [class*=" flaticon-"]:after {
  font-family: Flaticon;
        font-size: 20px;
font-style: normal;
margin-left: 20px;
}

.flaticon-address:before {
    content: "\f100";
    margin: auto;
    font-size: inherit;
 }
.flaticon-add:before {
    content: "\f101";
    margin: inherit;
    font-size: inherit;
}
.flaticon-save-file-option:before
{
    content: "\f102";
    margin: inherit;
    font-size: inherit;
}
.flaticon-road-perspective:before {
    content: "\f103";
    margin: inherit;
    font-size: inherit;
}
.flaticon-printer:before {
    content: "\f104";
    margin: inherit;
    font-size: inherit;
}
.flaticon-photo-camera:before {
    content: "\f105";
    margin: inherit;
    font-size: inherit;
}
.flaticon-delete:before {
    content: "\f106";
    margin: inherit;
    font-size: inherit;
}
.flaticon-send:before {
    content: "\f107";
    margin: inherit;
    font-size: inherit;
}
.flaticon-eye-variant-with-enlarged-pupil:before {
    content: "\f108";
    margin: inherit;
    font-size: inherit;
}
.flaticon-list-interface-symbol:before {
    content: "\f109";
    margin: inherit;
    font-size: inherit;
}
.flaticon-computing-cloud:before {
    content: "\f10a";
    margin: inherit;
    font-size: inherit;
}
.flaticon-blank-folded-page:before {
    content: "\f10b";
    margin: inherit;
    font-size: inherit;
}

footer {
    padding: 2rem;
    background-color: inherit;
    
    a {
        color: rgba(255, 255, 255, .5);
        text-decoration: underline;

        &:hover {
            color: $white;
        }
    }
}
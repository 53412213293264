.material-icons {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: inherit;
    line-height: inherit;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-feature-settings: 'liga';
    -webkit-font-smoothing: antialiased;
    vertical-align: bottom;
    user-select: none;
}

.single-icon {
    padding: .5rem;
    cursor: pointer;
    background-color: transparent;
    transition: .25s ease-in-out color, .25s ease-in-out background-color;
    border-radius: 4px;
    font-size: 1rem;
    color:white;

    &:hover {
        background-color: $m-red;
        color: $s-black!important;
    }
}

.single-icon-on-white {
    padding: .5rem 0.75rem;
    cursor: pointer;
    background-color: $m-light-gray;
    transition: .25s ease-in-out color, .25s ease-in-out background-color;
    border-radius: 4px;
    font-size: 1rem;
    color:$m-gray;

    &:hover {
        background-color: $m-light-gray;
        color: $s-black!important;
    }
}

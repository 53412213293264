.mat-mdc-chip-set {

    .mdc-evolution-chip-set__chips {
        align-items: center;
    }

    .mat-mdc-chip {
        &:hover {
            opacity: .50;
        }

        &.mat-mdc-chip-remove {
            opacity: unset;

            &:hover {
                opacity: .50;
            }
        }

        font-weight: 500;

        .mat-mdc-chip-action {
            cursor: default;
        }

        .mdc-evolution-chip__icon--trailing {
            height: 21px;
        }
    }
}


h1, h2, h3,
h4, h5, h6,
.h1, .h2, .h3,
.h4, .h5, .h6 {
    font-family: inherit;
    color: $s-black-000;
    margin-bottom: 1rem;
}

p {
    font-size: 1rem;
    line-height: 100%;
}

h1 {
    font-weight: 900!important;
}


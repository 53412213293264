.global-header {
    box-shadow: 0 3px 6px rgba(0, 0, 0, .12);
    position: relative;
    z-index: 1000;
}

.navbar-collapse {
    flex-grow: 0;
}

.bg-white {
    background-color: $s-white-fff;
}

.nav-item:hover {
    color: $m-red;
}

.user-account-profile img {
    width: 40px;
    height: 40px;
    border-radius: 100px;
}

.navbar-expand-lg .navbar-nav .nav-link {
    color: $s-blue;

    &:hover {
        color: $s-black;
    }
}

.navbar-nav .nav-item.active .nav-link {
    font-weight: bold;
    color: $s-black;
}

.navbar-toggler-icon {
    color: $primary;
}

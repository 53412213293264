@import "./_settings.colors.scss";

.validation-error {
    margin: .5em;
    color: red;
}

.closing-notes-activity-heading {
    color: $s-teal;
    font-weight: 550 !important;
}

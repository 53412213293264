// So far, this grid layout
// is being used on the customer profile page
.grid-layout {
    display: grid;
    grid-gap: 1rem;
    grid-column-gap: 1rem;
    grid-row-gap: 1rem;
    grid-template-columns: repeat(4, [col]);
    grid-template-rows: repeat(2, [row] auto);

    //
    // Hiding for now
    //grid-template-areas:
    //    "card--info"
    //    "card--call-history"
    //    "card--last-order"
    //    "card--contacts"
    //    "card--agreements"
    //    "card--wholesalers"
    //    "card--projects";

    //@media (min-width: 500px) {
    //    grid-template-columns: 2fr;
    //    grid-template-areas:
    //        "card--info card--call-history card--call-history card--call-order"
    //        "card--contacts card--agreements card--wholesalers card--projects";
    //}


    //@media (min-width: 700px) {
    //    grid-template-columns: 1fr;
    //    grid-template-areas:
    //        "card--info card--call-history card--call-history card--call-order"
    //        "card--contacts card--agreements card--wholesalers card--projects";
    //}

    //@media (min-width: 1200px) {
    //    grid-template-columns: 2fr 4fr 1fr 1fr;
    //    grid-template-areas:
    //        "card--info card--call-history card--call-order"
    //        "card--contacts card--agreements card--wholesalers card--projects";
    //
    //}

    &:not(:last-child) {
        margin-bottom: 1rem;
    }
}

//.g-2-col {
//    grid-template-columns: 9fr 3fr;
//}
//
//.g-3-col {
//    grid-template-columns: 1fr 2.039fr 1fr;
//}
//
//.g-4-col {
//    grid-template-columns: 1fr 1fr 1fr 1fr;
//}

//  These are the overview tiles on the
// customer profile overview
.customer-overview-tiles .grid-item {
    max-height: 25rem;
    overflow: scroll;
    overflow: hidden;
}

.card--info {
    grid-column: col 0 / span 1;
    grid-row: row 1 ;
}

.card--call-history {
    grid-column: col 1 / span 3;
    grid-row: row 1 ;
}

.card--last-order {
    grid-column: col 4 / span 1;
    grid-row: row 1 ;
}

.card--contacts {
    grid-column: col 0 / span 1;
    grid-row: row 2 ;
}

.card--agreements {
    grid-column: col 1 / span 1;
    grid-row: row 2 ;
}

.card--wholesalers {
    grid-column: col 2 / span 2;
    grid-row: row 2 ;
}

.card--projects {
    grid-column: col 4 / span 1;
    grid-row: row 2 ;
}

.card-project-release-dates {
    grid-column: col 0 / span 4;
    grid-row: row 1 ;
}
.card-project-stores {
    grid-column: col 4 / span 4;
    grid-row: row 1 ;
}
.card-project-products {
    grid-column: col 8 / span 4;
    grid-row: row 1 ;
}
.card-project-activities-and-surveys {
    grid-column: col 0 / span 4;
    grid-row: row 1 ;
}
.card-project-people-assignments {
    grid-column: col 4 / span 4;
    grid-row: row 1 ;
}
.card-project-order-dates {
    grid-column: col 8 / span 4;
    grid-row: row 1 ;
}

//closing notes
.card--order--transactions {
    grid-column: col 0 / span 1;
    grid-row: row 1 ;
}


.card--pictures {
    grid-column: col 1 / span 3;
    grid-row: row 1 ;
}

.card--distribution {
    grid-column: col 4 / span 2;
    grid-row: row 1 ;
}

.card--exchange--transactions {
    grid-column: col 0 / span 1;
    grid-row: row 2 ;
}

.card--activities--surveys {
    grid-column: col 1 / span 3;
    grid-row: row 2 ;
}

.card--call--comments {
    grid-column: col 4 / span 2;
    grid-row: row 2 ;
}

.mat-horizontal-content-container {
    padding: 0 0.5rem 0.5rem 0.5rem !important;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.mat-horizontal-stepper-header-container {
    padding: 0 0.5rem;
}

.mat-horizontal-stepper-header {
    height: 50px;
    padding: 0 0.5rem !important;
}

.mat-stepper-horizontal-line {
    margin: 0 !important;
    min-width: 2rem;
}

.mat-horizontal-stepper-content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.mat-horizontal-stepper-content[aria-expanded=false] {
    display: none;
}

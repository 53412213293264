.m-w-100 {
    @include media-breakpoint-down(md) {
        width: 100% !important;
    }
}

.show-mobile {
    display: none;
}

@media (max-width: 524px) {
    .mobile-justify-content-start {
        justify-content: flex-start;
    }

    .mobile-photo {
        flex-grow: 1;
        margin-bottom: 1rem;
        width: 100%;
    }

    .mobile-col {
        flex-direction: column;
    }

    .card {
        height: auto !important;
        max-height: none !important;
    }

    .card-body {
        overflow: auto;
    }

    .hide-mobile {
        display: none;
    }

    .show-mobile {
        display: flex;
    }

    .mat-vertical-content-container {
        margin-left: 0 !important;
    }

    .mat-vertical-stepper-content {
        max-width: 100vw;
    }

    .mat-vertical-content {
        padding: 0 !important;
    }

    .mobile-column {
        display: flex !important;
        width: 100%;

        & > section {
            width: 100%;
        }
    }

    .mobile-w-max {
        width: max-content;
    }
}

.mobile-card-container{
    .mobile-card:nth-child(n+1) {
        background: #fafafa;
    }
}

.worksheet-area {
    width: 100%;
    background-color: white;
}

.worksheet-static-scrollable {
    width: 100%;
    height: 90%;
    position: fixed;
    overflow-y: auto;
    background-color: white;
}

.worksheet-header {
    background: #f0f0f0;
    padding: 0.5rem;
}

.horizontal-nav {
    .list-inline {
        margin-bottom: 0;
        margin-top: 1rem;
    }

    .list-inline-item {
        margin-bottom: 0;
    }

    .list-inline-item:not(:last-child) { margin-right: .5rem; }

    .list-inline-item a {
        color: $s-grey-707;
        padding-bottom: 0rem;
        border-bottom: 2px solid transparent;
        transition: .25s ease-in-out border-bottom-color, .25s ease-in-out color;

        &:hover {
            color: $m-red;
            text-decoration: none;
            border-bottom-color: $m-red;
        }
    }

    .list-inline-item.active a {
        color: $m-red;
        border-bottom-color: $m-red;
    }
}

.small-label {
    font-size: 0.85rem;
    color: $s-grey-707;
}

.call-steps-area {
    display: flex;
    padding: 1rem;
    justify-content: space-between;
    align-items: center;
}

$arrow-width: 34px;
$arrow-offset: 32px;
$arrow-height: 25px;

.call-steps {
    margin: 0 1rem;
    padding: 0;
    display: flex;
    flex-wrap: wrap;

    .call-step {
        list-style: none;
        margin: 0;
        padding: 0;

        .step {
            align-items: center;
            background-color: $s-white-fff;
            box-shadow: 0 1px 2px rgba(0, 0, 0, .12);
            border: 1px solid $m-light-gray;
            color: $s-lite-red;
            cursor: pointer;
            display: flex;
            font-size: 12px;
            height: 50px;
            justify-content: center;
            padding: .5rem .5rem .5rem 1rem;
            position: relative;

            &:before,
            &:after {
                content: '';
                position: absolute;
                width: 0;
                height: 0;
            }

            &:before {
                border-top: $arrow-height solid transparent;
                border-bottom: $arrow-height solid transparent;
                border-left: $arrow-width solid $s-white-fff;
                right: -$arrow-offset;
                z-index: 10;
            }

            &:after {
                border-top: $arrow-height solid transparent;
                border-bottom: $arrow-height solid transparent;
                border-left: $arrow-width solid $m-light-gray;
                right: -$arrow-offset - 2;
                z-index: 9;
            }
        }

        .step:hover {
            color: $m-red;
        }

        &.active .step {
            background-color: $m-red;
            color: $s-white-fff;

            &:before {
                border-left: $arrow-width solid $m-red;
            }

            &:after {
                border-left: $arrow-width solid $m-red;
            }
        }

        &.completed .step {
            background-color: $s-extra-lite-red;
            color: rgba(162, 29, 42, 0.6);

            &:before {
                border-left: $arrow-width solid $s-extra-lite-red;
            }

            &:after {
                border-left: $arrow-width solid $m-light-gray;
            }
        }

        &.error .step {
            color: #a21d2a;
            border-color: #a21d2a;

            &:after {
                border-left: $arrow-width solid rgba(162, 29, 42, 0.6);
            }
        }

        &:not(:first-child) {
            .step {
                padding-left: $arrow-width * 1.25;
            }
        }
    }
}

.call-step-control {
    background-color: $s-white-fff;
    box-shadow: 0 1px 3px rgba(0, 0, 0, .12);
    -webkit-appearance: none;
    padding: .5rem .75rem;
    border-radius: 4px;
    transition: .25s ease-in-out box-shadow;
    border: 1px solid #636f82;
    color: $m-red;
    font-size: xx-large;

    &:hover {
        box-shadow: 0 6px 13px rgba(0, 0, 0, .22);
    }
}

.strip {
    display: flex;
    border-bottom: 1px solid $s-grey-d6d;
}

.left-strip,
.right-strip {
    flex: 1;
}

.right-strip {
    color: $s-black-000;
    text-align: right;
}

// Being Used on Sales/Gratis Step 4
.totals-area {
    width: 20%;
    box-shadow: 0 6px 12px rgba(0, 0, 0, .12);
    height: 100%;
    position: fixed;
    right: 0;
    padding: 2rem;
    display: none;
}

.main-container {
    width: 100%;
}

@use '@angular/material' as mat;

/* You can add global styles to this file, and also import other style files */
@import "@angular/material/prebuilt-themes/deeppurple-amber.css";



/* Importing Bootstrap SCSS file. */
@import '../node_modules/bootstrap/scss/bootstrap';

/* -- Remove spinners from input[number] elements -- */

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

/* -- -- */

html, body {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    min-width: 100vw;
    overflow: hidden;
}

body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}

@import "./scss/main.scss";

/*Time Picker Styles*/
@import "@danielmoncada/angular-datetime-picker/assets/style/picker.min.css";

.flex-basis-0 {
    flex-basis: 0 !important;
}

.mat-mdc-card {
    padding: 0.5rem;
}

//Formatting for Angular form fields.
mat-form-field {
    @include mat.form-field-density(-4);
    margin-bottom: 15px;
}

.mat-mdc-form-field-infix {
    margin: auto;
}

.mat-expansion-panel-header {
    padding: 0 0.5rem !important;
}

.mat-expansion-panel-body {
    padding: 0 1rem 1rem 1rem;
}

.mat-drawer-content {
    display: flex !important;
}

//Stepper configuration
.mat-horizontal-stepper-header-container {
    max-width: 100vw;
    overflow: scroll;
    scrollbar-width: none;
    &::-webkit-scrollbar {
        //For Safari
        display: none;
    }
}

mat-step-header {
    overflow: unset !important;
}

.sidenav-content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.navbar {
    padding: 0.5rem;
}

.cdk-global-scrollblock {
    overflow: hidden;
}

.card {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    max-height: none;
}

.card-header-btn {
    position: absolute;
    right: 1rem;
}

a {
    text-decoration: none !important;
}

.mat-stepper-horizontal {
    display: flex;
    flex-direction: column;
}

.facet {
    background: whitesmoke;
    box-shadow: none !important;
    border: 1px solid #e6e6e6;
    margin-bottom: 0.5rem;
    overflow: auto;
    padding: 0.5rem;
}

.sticky-header {
    top: 0 !important;
    position: sticky !important;
    z-index: 100 !important;
}

.mat-mdc-table th {
    top: -1px;
}

mat-icon.material-icons {
    display: flex;
    align-content: center;
    justify-content: center;
    flex-wrap: wrap;
}

.card {
    border: 1px solid rgba(99, 111, 130, 0.25);
    border: none;
    box-shadow: 0 2px 16px 4px rgba(99, 111, 130, 0.25);
}

.card-header {
    background-color: $m-red;
    color: $s-white-fff;
    border-bottom: 1px solid rgba(99, 111, 130, 0.25);
    //border-bottom: 0;
    position: sticky;
    top: 0;
    z-index: 100;

    &.light-bg {
        background-color: lighten($s-blue, 45%);
    }
}

.banner-header {
    background-color: $m-darkest-gray !important;
    color: $s-white-fff !important;
    border-bottom: 1px solid rgba(99, 111, 130, 0.25);
    position: sticky;
    top: 0;
    z-index: 3000;
}

.card-body {
    overflow-x: hidden;
    overflow-y: auto;
}

button.card-bottom {
    width: 76px;
    margin-right: 1em;
    margin-left: 1em;
    float: right;
}

.card-view-port {
    margin: 0.5em;
    margin-top: 0;
    border: 0.5px solid #EFF2F4;
    border-radius: 5px;
}

.card-view-port-scroll {
    margin-top: 1em;
    margin-left: 1em;
    margin-bottom: 1em;
}

.client-list-toggle,
.client-list-toggle:hover {
    color: $s-white-fff;
}

.inner-card {
    margin-top: 1em;
}

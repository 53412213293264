.form-control,
.custom-select {
    border-color: $s-grey-a3a;
    color: $s-grey-3d3;

    //@include media-breakpoint-down(sm) {
    //    height: calc(2em + 0.75rem + 2px);
    //}
}

.mw-7rem-form {
    max-width: 7rem;
}

.form-control:focus {
    border-color: $m-red;
    box-shadow: 0 5px 7px rgba(146, 9, 35, 0.54);
}

label {
    color: inherit;
}

.counter-control {
    max-width: 10rem;
    min-width: 7rem;
}

.input-w-icon   {
    position: relative;
    display: flex;
    align-content: center;
    align-items: center;
    flex: 1;
    color: $s-blue;

    .icon {
        position: absolute;
        left: 0.75rem;
    }

    .form-control {
        padding-left: 2rem;
        border: none;
        border: 1px solid lighten($s-blue, 35%);
        background-color: $white;
        box-shadow: 0 2px 4px rgba(99, 111, 130, 0.08);
        color: $s-grey-3d3;

        &:hover {
            box-shadow: 0 5px 14px rgba(99, 111, 130, 0.25);
        }

        &:focus {
            box-shadow: 0 5px 7px rgba(99, 111, 130, 0.55);
            border-color: $s-blue;
        }
    }
}
